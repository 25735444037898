import {
  CButton,
  CCard,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CRow,
} from '@coreui/react-pro'
import { Formik } from 'formik'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import logo2 from '../../../assets/img/bg-bottom.png'
import logo1 from '../../../assets/img/login-bg-1.jpg'
import { RootState } from '../../../reducers'
import { useRouter } from '../../../shared/utils/hooks/useRouter'
import { AppDispatch } from '../../../store'
import { ToastSuccess } from '../../shared/toast/Toast'
import { ILoginForm, login } from './auth.api'
import { fetching } from './auth.reducer'
import { NavLink } from 'react-router-dom'
import gif1 from '../../../assets/img/gif1.gif'
import logologin1 from '../../../assets/img/newimg/logo-login-1.png'
import login2 from '../../../assets/img/newimg/login2.png'

interface ILocationPath {
  path?: string
}

const Login1 = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { navigate, location } = useRouter()
  const state = location.state as ILocationPath
  const { user, loading, loginSuccess, firstTimeLogin } = useSelector(
    (state: RootState) => state.authentication,
  )

  const validationSchema = Yup.object().shape({
    username: Yup.string().trim().required('Không được để trống'),
    password: Yup.string().required('Không được để trống'),
  })

  useEffect(() => {
    if (user) {
      const redirectPath = state?.path || '/'
      navigate(redirectPath, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (loginSuccess && !firstTimeLogin) {
      ToastSuccess('Đăng nhập thành công')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess])

  const initialValues: ILoginForm = { username: '', password: '' }

  return (
    <div className="login-wrapper">
      <div className="login-top">
        <img width={'100%'} src={logologin1} />
      </div>
      <CRow className="login-fe login-fe-custom m-0">
        <CCol className="box-auto">
          <CRow className="align-items-center login-top-1">
            <CCol xs={12} className="px-0 text-center">
              {/* <img className="logo-top" src={gif1} width={150} /> */}

              {/* <h1 style={{ fontSize: 35, fontWeight: 700 }}>VALENTINO</h1> */}
              {/* <img src={logo} height={100} alt="vtrack_logo" /> */}
            </CCol>

            <CCol xs={12}>
              <CCard
                className="login-card py-5"
                style={{ background: 'transparent' }}
              >
                {/* <p className="text-display-bold-sm text-gray-modern-900 mb-5">Đăng nhập</p> */}
                <Formik
                  enableReinitialize
                  validationSchema={validationSchema}
                  initialValues={initialValues}
                  onSubmit={(values) => {
                    dispatch(fetching())
                    dispatch(login(values))
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <CForm onSubmit={handleSubmit} className="custom-form">
                      <CRow className="mb-3">
                        {/* <CCol xs={12}>
                        <p className="text-medium-sm mb-2">Tên đăng nhập</p>
                      </CCol> */}
                        <CCol xs={12}>
                          <CFormInput
                            value={values.username}
                            onChange={(
                              e: React.FormEvent<HTMLInputElement>,
                            ) => {
                              setFieldValue(
                                'username',
                                e.currentTarget.value.trim().toLowerCase(),
                              )
                            }}
                            type="text"
                            id="username"
                            name="username"
                            autoComplete="none"
                            placeholder="Tên đăng nhập"
                            onBlur={handleBlur}
                          />
                          <CFormFeedback
                            invalid
                            className={
                              !!errors.username && touched.username
                                ? 'd-block'
                                : 'd-none'
                            }
                          >
                            {errors.username}
                          </CFormFeedback>
                        </CCol>
                      </CRow>

                      <CRow className="mb-3">
                        {/* <CCol xs={12}>
                        <CRow className="justify-content-between">
                          <p className="text-medium-sm mb-2 w-auto">Mật khẩu</p>
                         
                        </CRow>
                      </CCol> */}
                        <CCol xs={12}>
                          <CFormInput
                            value={values.password}
                            onChange={handleChange}
                            type="password"
                            id="password"
                            name="password"
                            autoComplete="none"
                            placeholder="Mật khẩu"
                            onBlur={handleBlur}
                          />
                          <CFormFeedback
                            invalid
                            className={
                              !!errors.password && touched.password
                                ? 'd-block'
                                : 'd-none'
                            }
                          >
                            {errors.password}
                          </CFormFeedback>
                        </CCol>
                      </CRow>

                      <CRow className="m-0 justify-content-end">
                        <CButton
                          className="btn-custom primary-500 mt-3 w-auto"
                          type="submit"
                          disabled={loading}
                        >
                          Đăng nhập
                        </CButton>
                      </CRow>

                      <CRow className="mt-3 justify-content-end text-more">
                        <p className="text-white">
                          Chưa có tài khoản?{' '}
                          <NavLink to="/register" className="fw-bold text-white">
                            Đăng ký ngay
                          </NavLink>
                        </p>
                      </CRow>
                    </CForm>
                  )}
                </Formik>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <div className="w-100 login-bottom">
        <div className="bottom-b">
          <img width={'100%'} src={login2} />
        </div>
      </div>
    </div>
  )
}

export default Login1
