import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from "@coreui/react-pro";
import Layout from "./Layout";
import { useEffect, useState } from "react";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { Navigate } from "react-router-dom";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import axios from '../../../shared/config/axios-interceptor'
import { IHistory } from "@/shared/model/history.model";
import { RootState } from "@/reducers";
import { useSelector } from "react-redux";
import { mapResponseVotedHistory } from "@/shared/enumeration/ResponseStatus";

const HistoryOrder = () => {

    const { user } = useSelector((state: RootState) => state.authentication);

    const { navigate } = useRouter();

    const [activeKey, setActiveKey] = useState(1)


    const [opend, setOppend] = useState<IHistory[] | []>([])
    const [drawn, setDrawn] = useState<IHistory[] | []>([])

    const getHoSoDaMo = async () => {
        try {
            const response = await axios.get(
                '/history?status=closed&sortOrder=DESC&sortBy=historyId&limit=20&userId=' + user?.id,
            )
            setOppend(response.data.data)
        } catch (error) {
            setOppend([])
            console.error('Error:', error)
        }
    }


    const getHoSoChuaMo = async () => {
        try {
            const response = await axios.get(
                '/history?status=drawn&sortOrder=DESC&sortBy=historyId&limit=20&userId=' + user?.id,
            )
            setDrawn(response.data.data)
        } catch (error) {
            setDrawn([])
            console.error('Error:', error)
        }
    }

    useEffect(() => {
        getHoSoDaMo();
        getHoSoChuaMo();
    }, [])


    return <Layout >
        <div className="box-history-order">


            <div className="box-header">

                <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                <h4 className="text-white text-center mb-0 mt-1">Lịch sử tham gia</h4>

            </div>

            <CNav className="px-0" variant="tabs" role="tablist">
                <CNavItem>
                    <CNavLink
                        active={activeKey === 1}
                        onClick={() => setActiveKey(1)}
                    >
                        Đã mở thưởng
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink
                        active={activeKey === 2}
                        onClick={() => setActiveKey(2)}
                    >
                        Chưa mở thưởng
                    </CNavLink>
                </CNavItem>
            </CNav>

            <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>

                    <div className="box-history">

                        {
                            opend.map((item, index) => {
                                return <div className="item" key={index}>
                                    <div className="d-flex justify-content-between">
                                        <div style={{ color: "#f1c232" }}>Kỳ {item.KyId}</div>
                                        <div style={{ color: "#f1c232" }}>Tổng cộng: {new Intl.NumberFormat('vi-VN').format(item?.money || 0)}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div style={{ color: "#f1c232" }}>Bình chọn: {mapResponseVotedHistory[item.voted]}</div>
                                        <div style={{ color: 'red' }}>Trả thưởng {new Intl.NumberFormat('vi-VN').format(item?.addMoney || 0)}</div>
                                    </div>
                                </div>
                            })
                        }

                    </div>

                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="profile-tab" visible={activeKey === 2}>
                    <div className="box-history">
                        {
                            drawn.map((item, index) => {
                                return <div className="item" key={index}>
                                    <div className="d-flex justify-content-between">
                                        <div style={{ color: "#f1c232" }}>Kỳ {item.KyId}</div>
                                        <div style={{ color: "#f1c232" }}>Tổng cộng: {item.money}</div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div style={{ color: "#f1c232" }}>Bình chọn: {mapResponseVotedHistory[item.voted]}</div>
                                        <div style={{ color: 'red' }}>Trả thưởng {item.addMoney}</div>
                                    </div>
                                </div>
                            })
                        }
                    </div>

                </CTabPane>
            </CTabContent>



        </div>
    </Layout>
}

export default HistoryOrder;
