import TimeIcon from '@/components/shared/icons/web/TimeIcon'
import { IGame } from '@/shared/model/game.model'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import logo3 from '../../../assets/img/logo-3.png'
import time from '../../../assets/img/newimg/time.png'
import box3left from '../../../assets/img/newimg/box-3-left.png'

interface IData {
  setDisableDatCuoc: any
  setLoad: any
  load: boolean
  ky: IGame | null
}
const KyQuay1 = (props: IData) => {
  const { setDisableDatCuoc, setLoad, load, ky } = props

  const [timeInterval, setTimeInterval] = useState({
    miute: '00',
    send: '00',
  })

  useEffect(() => {
    const gameEndTime = dayjs(ky?.endTime).add(10, 'seconds')

    const interval = setInterval(() => {
      const now = dayjs()
      const difference = gameEndTime.diff(now)

      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60))
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60),
        )
        const seconds = Math.floor((difference % (1000 * 60)) / 1000)

        if (minutes === 0 && seconds < 15) {
          setDisableDatCuoc(true)
        } else {
          setDisableDatCuoc(false)
        }

        let m = minutes < 10 ? '0' + minutes : minutes
        let s = seconds < 10 ? '0' + seconds : seconds
        const formattedCountdown = `${m}:${s}`
        setTimeInterval({ miute: m.toString(), send: s.toString() })
      } else {
        setLoad(!load)
        // setTimeInterval('Đang chờ kết quả')
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [ky?.endTime, load])

  return (
    <div className="d-flex justify-content-between mt-3 box-custom-4 align-items-center">
      <div className="d-flex justify-content-start">
        <div className="item-left">
          <img src={box3left} />
          <div>Mã đơn hàng : {(ky?.id ?? 0) - 1}</div>
        </div>
      </div>

      <div className="w-100 text-center time-box">
        <img src={time} />
        <div className="time font-digital d-flex gap-1 align-items-center">
          <div>{timeInterval.miute}</div>
          <div>:</div>
          <div>{timeInterval.send}</div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <div className="item-right">
          <img style={{ transform: 'rotate(180deg)' }} src={box3left} />
          <div>Mã đơn tiếp theo : {ky?.id}</div>
        </div>
      </div>
    </div>
  )
}

export default KyQuay1
