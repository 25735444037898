import { NavLink } from 'react-router-dom'

const Info = () => {
  return (
    <div>
      <div
        className="d-flex justify-content-between p-3 border-bottom"
        style={{ backgroundColor: '#113b49' }}
      >
        <div className="fw-bold text-white">Quy định</div>
        <NavLink
          to={'/'}
          style={{ fontSize: 30, lineHeight: '20px', color: '#fff' }}
          className="text-decoration-none"
        >
          ×
        </NavLink>
      </div>

      <div className="p-3">
        <p className="fw-semibold">
          Prudentiall nghiêm cấm mọi hình thức mua hàng bằng danh tính giả, khi bị
          phát hiện, website có quyền rút toàn bộ số tiền thưởng. Nếu bạn có bất
          kỳ nhận xét hoặc đề xuất nào, vui lòng chọn "CSKH" trong web và để lại
          tin nhắn.
        </p>
        <p className="fw-semibold">
          Lưu ý web sẽ trích những phần quà ngẫu nhiên cho ID may mắn hàng tháng
          <br></br>- Điều kiện nhận thưởng :<br></br>
          Xác minh thủ tục theo chứng từ hóa đơn hệ thống khảo sát thành công
          mới đủ điều kiện nhận thưởng
        </p>
        <p className="fw-semibold">
          Để đảm bảo hệ thống được hoạt động lâu dài cũng như bắt buộc duy trì
          các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận
          được phần quà ngẫu nhiên may mắn từ hệ thống, khi rút tiền cần thực
          hiện đóng phí duy trì theo hạn mức rút tiền như sau:
        </p>

        <p className="fw-semibold my-5">Thuế:</p>

        <p className="fw-semibold">
          - Đối với Quý khách hàng rút hạn mức từ 150.000 điểm - 250.000 điểm
          vui lòng đóng mức thuế 15% / tổng số điểm Quý khách rút ra.
        </p>
        <p className="fw-semibold">
          - Đối với Quý khách hàng rút hạn mức từ 250.000 điểm - 499.000 điểm
          vui lòng đóng mức thuế 20% / tổng số điểm Quý khách rút ra.
        </p>
        <p className="fw-semibold">
          - Đối với Quý khách hàng rút hạn mức trên 500.000 điểm tương ứng
          500.000.000 VNĐ vui lòng đóng mức thuế 30% / tổng số điểm Quý khách
          rút ra.
        </p>
      </div>
    </div>
  )
}

export default Info
