import AccountIcon from "@/components/shared/icons/web/AccountIcon";
import CskhIcon from "@/components/shared/icons/web/CskhIcon";
import HeThongIcon from "@/components/shared/icons/web/HeThongIcon";
import HomeIcon from "@/components/shared/icons/web/HomeIcon";
import { NavLink } from "react-router-dom";

const MenuBottom = () => {
    return <div className="menu-bottom d-flex justify-content-between align-items-center">
        <NavLink to={'/'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <HomeIcon style={{ fill: '#fff' }} width={20} />
            <span>Trang chủ</span>
        </NavLink>
        <NavLink to={'/history'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <HeThongIcon  style={{ fill: '#fff' }}/>
            <span>Xu hướng</span>
        </NavLink>
        <NavLink to={'/account'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <AccountIcon style={{ fill: '#fff' }} />
            <span>Cá nhân</span>
        </NavLink>
        <NavLink to={'/message'} className="text-center w-100 d-flex flex-column justyfy-content-center align-items-center">
            <CskhIcon style={{ fill: '#fff' }} />
            <span>CSKH</span>
        </NavLink>
    </div>;
}

export default MenuBottom;