import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect } from 'react'
let i = 1;
const ChatIfame = () => {
  const { navigate } = useRouter()

  useEffect(() => {
  }, [])

  return (
    <div className="h-100">
      <iframe
        src="https://agencychatlive.com/widget?website_token=UMbnuv3iCK7pgkUyohYAKmMK&amp;cw_conversation=eyJhbGciOiJIUzI1NiJ9.eyJzb3VyY2VfaWQiOiI2MmFjNTc1MS00NTA5LTRkMDMtYjhiMC0xM2YyMzcwY2NlYTYiLCJpbmJveF9pZCI6Nn0.XtKhohu8n-deSIMUiYtgTU_fnmtDw_M3v_9gvFyhlHg"
        allow="camera;microphone;fullscreen;display-capture;picture-in-picture;clipboard-write;"
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export default ChatIfame
