export enum SystemRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  CHAT = 'CHAT',
}

export const systemRoleArray: SystemRole[] = [SystemRole.USER, SystemRole.ADMIN];

export const mapRoleToString: { [key in SystemRole]: string } = {
  [SystemRole.USER]: 'Người dùng',
  [SystemRole.ADMIN]: 'Quản trị viên',
  [SystemRole.CHAT]: 'Quản trị viên chat',
};

// Project Role

export enum RoleProjectEnum {
  MANAGER = 'MANAGER', // Quan ly
  EDITOR = 'EDITOR', // Bien tap vien
  STAFF = 'STAFF', // Nhan vien
}

export const roleProjectArray: RoleProjectEnum[] = [
  RoleProjectEnum.MANAGER,
  RoleProjectEnum.EDITOR,
  RoleProjectEnum.STAFF,
];

export const mapRoleProjectToString: { [key in RoleProjectEnum]: string } = {
  [RoleProjectEnum.MANAGER]: 'Quản lý',
  [RoleProjectEnum.EDITOR]: 'Biên tập viên',
  [RoleProjectEnum.STAFF]: 'Nhân viên',
};
